import { TValidator } from '@/components/shared/Form/types';
import {
  TConstants,
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import { UseFormReturn } from 'react-hook-form';
import { RainyDayAction } from './RainyDayAction';
import { CancelDecision } from './CancelDecision';
import { PARENT_LIST_ITEMS } from '../listItem';
import { shouldRenderCancelDecision } from '../Shared/shouldRenderPhotographyContent';

type DealingWithUnexpectedSituationsProps = {
  methods: UseFormReturn<TFormInputs>;
  photography: TPhotography;
  constants: TConstants;
  validator: TValidator;
};

export const DealingWithUnexpectedSituations: React.FC<DealingWithUnexpectedSituationsProps> =
  ({ photography, constants, validator }) => {
    return (
      <div className="c-frame">
        <div
          className="c-section_title"
          id={PARENT_LIST_ITEMS.dealingWithUnexpectedSituations.id}
        >
          {PARENT_LIST_ITEMS.dealingWithUnexpectedSituations.name}
        </div>
        <RainyDayAction
          photography={photography}
          constants={constants}
          validator={validator}
        />
        {shouldRenderCancelDecision(photography) && (
          <CancelDecision
            photography={photography}
            constants={constants}
            validator={validator}
          />
        )}
      </div>
    );
  };
