import { RAINY_DAY_ACTION_ID_NO_CANCEL } from '@/components/pages/Events/Show/Photography/const';
import { TPhotography } from '@/components/pages/Events/Show/Photography/types';

export const isMeetingPlaceDifferentBetweenSunnyAndRainy = (
  photography: TPhotography
): boolean => {
  if (photography?.rainyDayActionId !== RAINY_DAY_ACTION_ID_NO_CANCEL) {
    return false;
  }
  return photography.isMeetingPlaceDifferentBetweenSunnyAndRainy ?? false;
};
