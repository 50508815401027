import {
  EVENT_TYPE_ID_ENTRANCE_CEREMONY,
  EVENT_TYPE_ID_FIELD_TRIPS,
  EVENT_TYPE_ID_GRADUATION_CEREMONY,
  EVENT_TYPE_ID_RECITAL,
  EVENT_TYPE_ID_RECITAL_REHEARSAL,
  EVENT_TYPE_ID_SLEEPOVER,
  EVENT_TYPE_ID_SPORTS_DAY,
  EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE,
  PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM,
  RAINY_DAY_ACTION_ID_NO_CANCEL,
  REQUEST_OPTION_ID_ALBUM,
  TRANSPORTATION_TYPE_BICYCLE,
  TRANSPORTATION_TYPE_CAR,
  TRANSPORTATION_TYPE_MOTORCYCLE,
} from '@/components/pages/Events/Show/Photography/const';
import {
  TFormInputs,
  TPhotography,
} from '@/components/pages/Events/Show/Photography/types';
import dayjs from 'dayjs';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

export const shouldRenderDocumentInformationFromGroup = (
  photography: TPhotography
): boolean => photography.hasUncreatedDocuments !== null;

export const shouldRenderPhotographingDayCount = (
  photography: TPhotography
): boolean => photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER;

export const shouldRenderMeetingPlaceCarParkingLot = (
  inputValues: TFormInputs
): boolean =>
  inputValues.meetingPlaceTransportationTypeIds.includes(
    TRANSPORTATION_TYPE_CAR
  ) ||
  inputValues.meetingPlaceTransportationTypeIds.includes(
    TRANSPORTATION_TYPE_MOTORCYCLE
  );

export const shouldRenderMeetingPlaceBicycleParkingLot = (
  inputValues: TFormInputs
): boolean =>
  inputValues.meetingPlaceTransportationTypeIds.includes(
    TRANSPORTATION_TYPE_BICYCLE
  ) ||
  inputValues.meetingPlaceTransportationTypeIds.includes(
    TRANSPORTATION_TYPE_MOTORCYCLE
  );

export const shouldRenderSpecifiedPosition = (
  photography: TPhotography
): boolean =>
  [
    EVENT_TYPE_ID_ENTRANCE_CEREMONY,
    EVENT_TYPE_ID_GRADUATION_CEREMONY,
    EVENT_TYPE_ID_SPORTS_DAY,
    EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
    EVENT_TYPE_ID_RECITAL,
    EVENT_TYPE_ID_RECITAL_REHEARSAL,
  ].includes(photography.eventTypeId);

export const shouldRenderSignboardPhoto = (
  photography: TPhotography
): boolean =>
  [EVENT_TYPE_ID_ENTRANCE_CEREMONY, EVENT_TYPE_ID_GRADUATION_CEREMONY].includes(
    photography.eventTypeId
  );

export const shouldRenderTripod = (photography: TPhotography): boolean =>
  [
    EVENT_TYPE_ID_ENTRANCE_CEREMONY,
    EVENT_TYPE_ID_GRADUATION_CEREMONY,
    EVENT_TYPE_ID_RECITAL,
    EVENT_TYPE_ID_RECITAL_REHEARSAL,
  ].includes(photography.eventTypeId) ||
  photography.requestOptions.includes(REQUEST_OPTION_ID_ALBUM);

export const shouldRenderRentalStepladder = (
  photography: TPhotography
): boolean =>
  [
    EVENT_TYPE_ID_SPORTS_DAY,
    EVENT_TYPE_ID_SPORTS_DAY_REHEARSAL,
    EVENT_TYPE_ID_RECITAL,
    EVENT_TYPE_ID_RECITAL_REHEARSAL,
  ].includes(photography.eventTypeId);

export const shouldRenderAlbumOption = (photography: TPhotography): boolean =>
  photography.requestOptions.includes(REQUEST_OPTION_ID_ALBUM);

export const shouldRenderRest = (photography: TPhotography): boolean => {
  dayjs.extend(CustomParseFormat);
  return (
    dayjs(photography.photographingEndTime, 'HH:mm').diff(
      dayjs(photography.photographingStartTime, 'HH:mm'),
      'hour'
    ) >= 6 &&
    (photography.eventTypeId !== EVENT_TYPE_ID_SLEEPOVER ||
      (photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER &&
        photography.canSleepover === false))
  );
};

export const shouldRenderTieredPlatform = (
  photography: TPhotography
): boolean => {
  return photography.equipmentTypeIds.includes(
    PHOTOGRAPHY_EQUIPMENT_TYPE_ID_TIERED_PLATFORM
  );
};

export const shouldRenderBackPaper = (photography: TPhotography): boolean => {
  return photography.equipmentTypeIds.includes(
    PHOTOGRAPHY_EQUIPMENT_TYPE_ID_BACK_PAPER
  );
};

export const shouldRenderOtherSpecialEquipment = (
  photography: TPhotography
): boolean => {
  return photography.equipmentTypeIds.includes(
    PHOTOGRAPHY_EQUIPMENT_TYPE_ID_OTHER_SPECIAL_EQUIPMENT
  );
};

export const shouldRenderStrobe = (photography: TPhotography): boolean => {
  return photography.equipmentTypeIds.includes(
    PHOTOGRAPHY_EQUIPMENT_TYPE_ID_STROBE
  );
};

export const shouldRenderPhotographerExpense = (
  photography: TPhotography
): boolean => {
  return (
    photography.eventTypeId === EVENT_TYPE_ID_FIELD_TRIPS ||
    photography.eventTypeId === EVENT_TYPE_ID_SLEEPOVER
  );
};

export const shouldRenderCancelDecision = (
  photography: TPhotography
): boolean => {
  return (
    photography.isMeetingPlaceDifferentBetweenSunnyAndRainy ||
    photography?.rainyDayActionId !== RAINY_DAY_ACTION_ID_NO_CANCEL
  );
};
